<template>
	<div class="w-full h-full flex flex-col">
		<Header>
			<div>
				<h1>Innstillinger</h1>
				<div class="flex space-x-4 justify-center sm:justify-start">
					<div>for {{account.name}}</div>
					<div class="bg-beige-dark text-gray-dark pl-2 pr-1 rounded leadin-none text-sm">
						<span @click="copy">
							#{{account.accountId}}
							
							<i class="fas fa-copy text-xs inline-block ml-1" />
						</span>
						
						<input 
							@focus="$event.target.select()" 
							ref="myinput" 
							readonly 
							:value="account.accountId"
							class="opacity-0 w-1"
						/>
					</div>
				</div>
			</div>
		</Header>
		
		<div class="flex-grow overflow-auto p-6 md:p-12">
			<form 
				v-if="accountEdit"
				@submit.prevent="onUpdateAccount()">
				
				<div class="md:grid grid-cols-2 gap-6">
					<div class="mb-10">
						
						<InputSingleline 
							v-model="accountEdit.name"
							:required="true"
							label="Bedriftnavn"
							name="name"
							:showRequiredWarning="false"
							class="flex-1"
							placeholder="Bedriftnavn"
							ref="autofocus"
						/>
						
						<InputSelect 
							v-model="accountEdit.industry"
							:required="true"
							label="Bransje"
							name="industry"
							class="flex-1 mb-4"
							:showRequiredWarning="false"
							:options="industriesArray"
						/>
						
						<InputSingleline 
							v-model="accountEdit.contact.email"
							label="E-postadresse"
							name="email"
							:required="true"
						/>
						
						<InputSingleline 
							v-model="accountEdit.contact.phone"
							label="Telefonnummer"
							name="phone"
							:required="false"
						/>
					</div>
					<div class="">
						<InputSingleline 
							v-model="accountEdit.contact.street"
							label="Gate"
							name="street"
							:required="true"
						/>
						
						<div class="grid grid-cols-2 gap-6 -my-4">
							<InputSingleline 
								v-model="accountEdit.contact.postcode"
								label="Postnummer"
								name="postcode"
								:required="true"
							/>
							
							<InputSingleline 
								v-model="accountEdit.contact.city"
								label="By"
								name="city"
								:required="true"
							/>
						</div>
						
						<InputSingleline 
							v-model="accountEdit.contact.country"
							label="Land"
							:name="'country'"
							class="w-full"
							:required="true"
						/>
						
						<!-- <label for="locale">Språk</label>
						<select 
							name="locale"
							v-model="accountEdit.locale">
							
							<option value="nb">Norsk</option>
							<option value="en">English</option>
						</select> -->
					</div>
				</div>
				
				<div class="md:grid grid-cols-2 gap-6">
					<div class="mb-10">
						<label for="departmentTargetUnit">Periode for Måloppnåelser pr. avdelinger:</label>
						<select 
							name="departmentTargetUnit"
							v-model="accountEdit.departmentTargetUnit">
							
							<option value="week">{{unitAliasToName('week')}}</option>
							<option value="month">{{unitAliasToName('month')}}</option>
							<option value="quarterly">{{unitAliasToName('quarterly')}}</option>
							<option value="year">{{unitAliasToName('year')}}</option>
						</select>
						
						<div 
							v-if="accountEdit.departmentTargetUnit != account.departmentTargetUnit"
							class="bg-yellow-200 px-8 mt-2 overflow-hidden">
							<!-- class="bg-orange-400 text-white rounded mt-4 px-8 overflow-hidden"> -->
							
							<label 
								for="departmentTargetUnitWarningOkToProceed"
								class="flex space-x-4 items-start">
								
								<input 
									type="checkbox" 
									id="departmentTargetUnitWarningOkToProceed"
									v-model="departmentTargetUnitWarningOkToProceed"
								/>
								<div>
									<strong>Advarsel</strong>
									<p>
										Du er nå i ferd med å endre "Periode for Måloppnåelse pr. avdelinger." ifra {{ unitAliasToName( account.departmentTargetUnit ) }} til {{ unitAliasToName( accountEdit.departmentTargetUnit ) }}.<br>
										Dette får konsekvenser for alle mål som er satt i alle avdelinger.<br>
										Husk å endre verdien "Måloppnåelse" i alle avdelinger slik at måloppnåelsen gjenspeiler den nye perioden.<br>
										Hvis du ikke ønsker å endre "Periode for Måloppnåelse pr. avdelinger" klikk avbryt.
									</p>
								</div>
							</label>
						</div>
					</div>
					<div class="">
						<InputSingleline 
							v-model="accountEdit.accountIdParent"
							label="Morselskap"
							name="accountIdParent"
							:required="false"
							placeholder='Upsell konto-id (eks: "acc_123.abc")'
						/>
					</div>
				</div>
				
				
				<div class="md:grid grid-cols-2 gap-6">
					<div class="mb-10">
						<label>Topplister i Oversikt / Dashboards</label>
						<select v-model="accountEdit.leaderboard.active">
							<option :value="false">Ikke vis topplister</option>
							<option :value="true">Vis topplister</option>
						</select>
					</div>
					<div>
						<label>	tall toppliste-plasseringer</label>
						<select 
							v-model.number="accountEdit.leaderboard.positions"
							:readonly="!accountEdit.leaderboard.active"
							:disabled="!accountEdit.leaderboard.active">
							
							<option
								v-for="position in 10"
								:key="'position_'+position">
								
								{{position}}
							</option>
						</select>
					</div>
				</div>
				
				
				
				<div class="mb-10">
					<label>Transaksjonsanimasjon</label>
					<select v-model="accountEdit.transactionNoticeShow">
						<option :value="true">Vis animasjon</option>
						<option :value="false">Ikke vis animasjon</option>
					</select>
				</div>
				
				
				
				
				
				
				
				
				
				
				
				
				
				<label for="logo">Logo</label>
				<div 
					v-if="accountEdit.logo"
					class="w-full p-8 object-contain bg-beige relative">
					
					<img 
						:src="fileToUrl(accountEdit.logo)"
						alt="Logo"
						class="object-contain h-32 mx-auto"
						style="max-width: 20rem;"
					/>
					
					<div class="absolute top-0 right-0 m-2">
						<i 
							class="bg-white p-2 fas fa-trash ml-2"
							@click="accountEdit.logo = null"
						/>
					</div>
				</div>
				<InputUpload
					v-else
					label="Last opp bedriftslogo"
					name="logo"
					:multiple="false"
					@input="onImageAdded($event)"
				/>
				
			</form>
			
			<div class="flex-none space-x-4 text-right my-12">
				<button 
					type="submit"
					@click.prevent="onUpdateAccount()" 
					:disabled="loading == 'updating'"
					class="button submit"
					:class="{'loading' : loading == 'updating'}">
					
					Lagre
				</button>
			</div>
			
			
			<div class="bg-beige-dark p-6 mb-6">
				<h2>Opprett ny Upsell-konto</h2>
				<p>
					Om du trenger en ny Upsell-konto kan du trykke på knappen under. Da beholder du din bruker logget inn, og en ny Upsell-konto vil bli tilknyttet din eksisterende bruker.
				</p>
				
				<div class="flex-none space-x-4">
					<router-link 
						:to="{ name: 'accounts', query: { showNewAccount: true } }"
						class="button dark">
						
						Opprett ny Upsell-konto
					</router-link>
				</div>
			</div>
			
			
			<div class="bg-beige-dark p-6 mb-6">
				<h2>Slett denne Upsell-kontoen</h2>
				<p>
					Om du velger å slette denne Upsell-kontoen, så vil alle brukeres tilgang til denne kontoen bli slettet, og du som administrator får ikke logget inn igjen. 
				</p>
				
				<div class="flex">
					<button 
						type="submit"
						@click.prevent="onDeleteAccount()" 
						:disabled="loading == 'deleting'"
						class="button warning"
						:class="{'loading' : loading == 'deleting'}">
						
						Slett
					</button>
					
					<div class="flex items-center ml-6">
						<input 
							type="checkbox" 
							v-model="deleteConsent"
						/>	
						
						Jeg forstår konsekvensene av å slette kontoen.
					</div>
				</div>
			</div>
			
			
			<a 
				class="mt-6 block"
				@click="showForDevelopers = !showForDevelopers">
				
				<span>{{ showForDevelopers ? 'Skjul' : 'Vis' }}</span> informasjon for utviklere
			</a>
			
			<div 
				v-if="showForDevelopers"
				class="flex-grow overflow-auto bg-beige-light border border-beige-dark p-8 mt-6">
				
				<h1>For utviklere</h1>
				<br>
				
				<h2>signedInUser</h2>
				<pre>{{signedInUser.attributes}}</pre>
				<br>
				
				<h2>account</h2>
				<pre>{{account}}</pre>
				<br>
				
				<h2>version</h2>
				{{version}}
				
				<br>
				<br>
				<br>
				
				<button 
					class="button submit"
					@click="onRefreshAll()">
					
					Force Full Refresh
				</button>
			</div>
			
			
		</div>
		
		
	</div>
</template>

<script>
	export default {
		components: {
			// Users,
		},
		
		mixins: [
			// AuthMixins,
		],
		
		computed: {
			
		},
		
		data(){
			return {
				loading: null,
				accountEdit: null,
				showForDevelopers: false,
				departmentTargetUnitWarningOkToProceed: false,
				deleteConsent: false,
			}
		},
		
		methods: {
			unitAliasToName( unitAlias ){
				switch (unitAlias) {
					case 'week':
						return 'Uke';
						break;
					case 'month':
						return 'Måned';
						break;
					case 'quarterly':
						return 'Kvartal';
						break;
					case 'year':
						return 'År';
						break;
					default:
						break;
				}
			},
			
			onImageAdded( file ){
				this.accountEdit.logo = file;
			},
			
			copy() {
				this.$refs.myinput.focus();
				document.execCommand('copy');
				this.$notify({ type: 'success', text: 'Kopiert til clipboard' });
			},

			onRefreshAll(){
				if ('serviceWorker' in navigator) {
					navigator.serviceWorker.getRegistrations().then( registrations => {
						for(let registration of registrations) {
							registration.unregister()
						}
					});
				}
				
				location.reload();
			},
			
			createCopy(){
				this.accountEdit = JSON.parse(
					JSON.stringify({
						departmentTargetUnit: 'week',
						locale: 'en',
						logo: null,
						leaderboard: {
							active: false,
							positions: 5,
						},
						// transactionNoticeShow: true,
						...this.account,
					})
				);
			},
			
			async onUpdateAccount(){
				try {
					if ( !this.accountEdit.name ) {
						throw Error('En Upsell-konto må ha et navn');
					}
					if ( 
						(this.account.departmentTargetUnit != this.accountEdit.departmentTargetUnit) &&
						!this.departmentTargetUnitWarningOkToProceed) {
						
						throw Error('Du må krysse av for å ha forstått konsekvensen av å endre enhet for måloppnåelse før vi kan lagre.');
					}
					
					
					this.loading = 'updating';
					await this.$store.dispatch('updateAccount', this.accountEdit);
					
					this.$notify({ type: 'success', text: 'Endringen ble lagret' });
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message || 'Kunne ikke lagre endringen'
					});
			
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			async onDeleteAccount(){
				try {
					if ( !this.deleteConsent ) {
						throw Error('Du må krysse av for å ha forstått konsekvensene av å slette kontoen');
					}
					
					this.loading = 'deleting';
					await this.$store.dispatch('deleteAccount');
					
					this.$router.push({
						name: 'accounts', 
					});
					
					this.$notify({ type: 'success', text: 'Kontoen ble slettet' });
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message || 'Kunne ikke slette kontoen'
					});
			
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
		},
		
		mounted(){
			this.createCopy();
			// this.version = process.env.VUE_APP_VERSION;
		},
	}
</script>